<template>
  <v-layout v-if="getPermission('product:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Product Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                  >Name</label
                >

                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Model</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  label="Name"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.name"
                  :rules="[
                    validateRules.required(productCreate.name, 'Name'),
                    validateRules.minLength(productCreate.name, 'Name', 1),
                    validateRules.maxLength(productCreate.name, 'Name', 100),
                  ]"
                ></v-text-field>

                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Model"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.barcode"
                ></v-text-field>
              </div>
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Part Number</label
                >
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  for="serial_number"
                  >Serial Number</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  label="Part Number"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.part_number,
                      'Part Number',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.part_number,
                      'Part Number',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.part_number"
                ></v-text-field>
                <v-text-field
                  id="serial_number"
                  dense
                  filled
                  label="Serial Number"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.serial_number"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Category</label
                >
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Brand</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="categoryList"
                  label="Category"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="Categorydata"
                  item-text="text"
                  item-value="id"
                  class="pr-2 width-100"
                  v-model.trim="productCreate.category"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Category(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  :items="brandList"
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  label="Brand"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="BrandData"
                  item-text="text"
                  item-value="id"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.brand"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Brand(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>

              <div class="d-flex w-50">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Website URL</label
                >
              </div>
              <div class="d-flex w-50">
                <v-text-field
                  dense
                  filled
                  label="Website URL"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.website_url"
                ></v-text-field>
              </div>

              <!-- <div class="d-flex">
                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                        >Manufacturer</label
                      >

                      <label class="pl-2 font-weight-700 font-size-16 width-100"
                        >Brand</label
                      >
                    </div>
                    <div class="d-flex">
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="manufacturerList"
                        label="Manufacturer"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="manageManufacturerDialog = true"
                        item-text="text"
                        item-value="id"
                        class="pr-2 width-100"
                        v-model.trim="productCreate.manufacturer"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Manufacturer(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        :items="brandList"
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        label="Brand"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="manageBrandDialog = true"
                        item-text="text"
                        item-value="id"
                        class="pl-2 width-100"
                        v-model.trim="productCreate.brand"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Brand(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </div> -->
            </v-card-text>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="pl-2 font-weight-700 font-size-16"
                >Description</label
              >
              <v-textarea
                dense
                filled
                label="Description"
                solo
                flat
                row-height="20"
                auto-grow
                color="cyan"
                v-model.trim="productCreate.description"
                :rules="[
                  validateRules.minLength(
                    productCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.description,
                    'Description',
                    1024
                  ),
                ]"
              ></v-textarea>
            </v-card-text>
          </v-card>

          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Project Price</label
                >

                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Threshold Price</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Project Price"
                  solo
                  flat
                  class="pr-2 width-100"
                  type="number"
                  min="0"
                  max="9999"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.project_price"
                  v-on:keypress="
                    limitDecimal($event, productCreate.project_price)
                  "
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Threshold Price"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    limitDecimal($event, productCreate.threshold_price)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.threshold_price"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Cost Price</label
                >

                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Selling Price</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Cost Price"
                  solo
                  flat
                  class="pr-2 width-100"
                  type="number"
                  min="0"
                  max="9999"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.purchase_cost"
                  v-on:keypress="
                    limitDecimal($event, productCreate.purchase_cost)
                  "
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Selling Price"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    limitDecimal($event, productCreate.selling_cost)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.selling_cost"
                ></v-text-field>
              </div>

              <div v-if="false" class="d-flex w-50">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Commission Rate (%)</label
                >
              </div>
              <div v-if="false" class="d-flex w-50">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Commission Rate (%)"
                  solo
                  flat
                  class="pr-2 width-100"
                  type="number"
                  min="0"
                  max="9999"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.commission_rate"
                  v-on:keypress="
                    limitDecimal($event, productCreate.commission_rate)
                  "
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Stock Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex w-50">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100 required"
                  >UOM
                </label>
                <label
                  v-if="!updateMode && false"
                  class="pl-2 font-weight-700 font-size-16 width-100 required"
                  >Initial Stock</label
                >
              </div>
              <div class="d-flex w-50">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="unitList"
                  label="UOM"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="UomData"
                  item-text="text"
                  item-value="id"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.unit"
                  :rules="[validateRules.required(productCreate.unit, 'UOM')]"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Unit(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-if="!updateMode && false"
                  dense
                  filled
                  color="cyan"
                  label="Initial Stock"
                  solo
                  flat
                  :rules="
                    productCreate.product_type == 'goods'
                      ? [
                          validateRules.required(
                            productCreate.initial_stock,
                            'Initial Stock'
                          ),
                        ]
                      : []
                  "
                  v-mask="'######'"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.initial_stock"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <template>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="UPC"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.upc, 'UPC', 1),
                  validateRules.maxLength(productCreate.upc, 'UPC', 100),
                ]"
                v-model.trim="productCreate.upc"
                class="pr-2 width-100"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                label="SKU"
                solo
                flat
                color="cyan"
                :rules="[
                  validateRules.minLength(productCreate.sku, 'SKU', 1),
                  validateRules.maxLength(productCreate.sku, 'SKU', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.sku"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="HSN Code"
                solo
                flat
                :rules="[
                  validateRules.minLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    100
                  ),
                ]"
                class="pr-2 width-100"
                v-model.trim="productCreate.hsn_code"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="EAN"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.ean, 'EAN', 1),
                  validateRules.maxLength(productCreate.ean, 'EAN', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.ean"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                label="ISBN"
                solo
                flat
                color="cyan"
                class="pr-2 width-100"
                :rules="[
                  validateRules.minLength(productCreate.isbn, 'ISBN', 1),
                  validateRules.maxLength(productCreate.isbn, 'ISBN', 100),
                ]"
                v-model.trim="productCreate.isbn"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                label="Part Number"
                solo
                flat
                color="cyan"
                class="pl-2 width-100"
                :rules="[
                  validateRules.minLength(
                    productCreate.part_number,
                    'Part Number',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.part_number,
                    'Part Number',
                    100
                  ),
                ]"
                v-model.trim="productCreate.part_number"
              ></v-text-field>
            </div>
          </template>
          <div class="d-flex" v-if="false">
            <label class="custom-form-label">Tax Preference</label>
            <v-radio-group
              class="pt-0 mt-0 mb-2"
              dense
              row
              color="cyan"
              v-model.trim="productCreate.taxable"
            >
              <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
              <v-radio
                label="Non-Taxable"
                color="cyan"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>

          <template> </template>
          <template v-if="false">
            <div v-if="$hasWarranty && getPermission('warranty:create')">
              <v-checkbox
                class="mt-0 width-100"
                color="cyan"
                v-model.trim="warrantyActive"
              >
                <template v-slot:label>
                  <label class="custom-form-label m-0 width-100"
                    >Warranty</label
                  >
                </template>
              </v-checkbox>
              <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <template v-for="(warranty, index) in productCreate.warranty">
                  <div class="d-flex" :key="index">
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      color="cyan"
                      label="Duration"
                      solo
                      flat
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      color="cyan"
                      hide-details
                      solo
                      flat
                      item-color="cyan"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      label="Type"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                    <div v-if="false" class="pl-2 width-50">
                      <template v-if="index > 0">
                        <v-btn
                          class="mx-2"
                          color="danger"
                          dark
                          fab
                          small
                          v-on:click="removeWarranty(index, warranty.id)"
                        >
                          <v-icon dark> mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          class="mx-2"
                          color="cyan"
                          dark
                          fab
                          small
                          v-on:click="pushWarranty"
                        >
                          <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <br />
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Product Images
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Images
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 images are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="productCreate.product_images"
                      v-on:file:updated="updateImages"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="productCreate.product_attachments"
                      v-on:file:updated="updateAttachments"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manageManufacturerDialog"
      :manufacturer="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    ></ManageManufacturer>
    <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import {
  QUERY,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import { map, compact } from "lodash";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    ManageUnit,
  },
  data() {
    return {
      proDuplicateId: null,
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      productCreate: {
        name: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        project_price: null,
        threshold_price: null,
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        attachments: [],
        product_images: [],
        product_attachments: [],
        website_url: null,
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }

        this.$nextTick(() => {
          if (param.warranty && param.warranty.length) {
            const item = compact(map(param.warranty, "id"));
            if (item && item.length) {
              this.warrantyActive = true;
            }
          }
        });
      },
    },
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },

    updateImages(param) {
      this.productCreate.images = param;
    },
    updateAttachments(param) {
      this.productCreate.attachments = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          /* if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode &&
            !this.productCreate.barcode
          ) {
            _this.productCreate.barcode = response.data.barcode;
          } */

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.supplier
          ) {
            _this.supplierList = response.data.supplier;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              _this.productCreate.description =
                _this.barcodeSetting.description;
            }

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
